const brandAssetsSubText =
  "Thank you for your interest in SHAPE.<br>View our guidelines to help you with our brand resources."

const ourBrandSubText =
  "Think of SHAPE, think of people, science and flourishing.<br><br>We’ve spent years studying flourishing at work, how it affects us, and how to harness it.<br>That’s us, we pioneered that.<br><br>We realised the world needed flourishing, but through education, encouragement and inspiration.<br><br><b>That’s our mission; to help the world’s companies flourish. And do it faster.</b><br><br>That’s why SHAPE is creative, compelling and pioneering."

const ourNameSubText = `We go simply by the name SHAPE.<br><br>Throughout our content, you'll spot "SHAPE" is always displayed in uppercase letters. That’s because it was
conceived as an important and distinguished representation of the positive transformation flourishing
brings.<br><br>So, whether you're crafting content, engaging with our brand, or simply spreading the word, always use 
CAPS when it comes to SHAPE.`

const theLogoSubText = `The SHAPE logo is circular to symbolise continuity and the ongoing journey of flourishing. It's a
powerful emblem of emergence, intrigue and growth. The logo is all about representing ‘living and 
being.’<br><br>The colour choice reinforces SHAPE’s commitment to being a reliable and trustworthy partner,
founded on scientific strength as well as a guardian of the highest quality data`

const mascotSubText = `The logo transforms into our mascot. It communicates using helpful visual imagery. This visual
metaphor reinforces SHAPE's role as a facilitator in bringing out the best in individuals.<br><br>The mascot is exclusively for use by SHAPE only.`

const exclusionZone = `No text or design elements should compromise the exclusion zone of the logo.<br>The specific boundaries of this exclusion zone are represented with the “H” of the logotype and
measured as shown here.`

const legalReminderSubText = `This is a friendly legal reminder that these graphics are proprietary and protected under
intellectual property laws.`

const logoDontsText = [
  {
    content: "DON’T outline logo",
  },
  {
    content: "DON’T add shadow to logo",
  },
  {
    content:
      "DON’T use the logo on any colour other than its specified background",
  },
  {
    content: "DON’T rotate logo",
  },
  {
    content: "DON’T alter the logo colours",
  },
  {
    content: "DON’T change the size ratios of the circle and logo",
  },
]

const shapeLogoImages = require.context(
  "../../images/shapelogos",
  false,
  /\.(png|jpe?g|svg)$/
)

const shapeLogos = [
  {
    name: "Full Colour",
    imgSrc: shapeLogoImages("./shape-logo-full-color.png").default,
  },
  {
    name: "Inverse",
    imgSrc: shapeLogoImages("./shape-logo-inverse.png").default,
  },
  {
    name: "All White",
    imgSrc: shapeLogoImages("./shape-logo-all-white.png").default,
  },
  {
    name: "Grayscale on White",
    imgSrc: shapeLogoImages("./shape-logo-grayscale-on-white.png").default,
  },
  {
    name: "Grayscale on Black",
    imgSrc: shapeLogoImages("./shape-logo-grayscale-on-black.png").default,
  },
]
const shapeBrandColors = require.context(
  "../../images/brandcolors",
  false,
  /\.(png|jpe?g|svg)$/
)

const brandColor = [
  {
    name: "Blue",
    imgSrc: shapeBrandColors("./brand-color-blue.png").default,
  },
  {
    name: "Grey",
    imgSrc: shapeBrandColors("./brand-color-grey.png").default,
  },
  {
    name: "Black",
    imgSrc: shapeBrandColors("./brand-color-black.png").default,
  },
]

export {
  brandAssetsSubText,
  ourBrandSubText,
  ourNameSubText,
  theLogoSubText,
  mascotSubText,
  shapeLogos,
  exclusionZone,
  logoDontsText,
  brandColor,
  legalReminderSubText,
}
