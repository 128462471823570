import React from "react"
import "./brandassets.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BrandAssets from "../components/brand-assets/brand-assets"
import { graphql } from "gatsby"

const BrandAssetsPage = ({ data }) => {
  console.info({ data })
  return (
    <Layout>
      <SEO title="Press Kit" />
      <BrandAssets data={data} />
    </Layout>
  )
}

export default BrandAssetsPage

export const pageQuery = graphql`
  query BrandAssetsQuery {
    contentfulBrandAssets {
      aliPdf
      johnPdf
      bookOfFlourishing
      bofEmbeddingInstructions
      mediaKit
    }
  }
`
